.dir{
    direction: rtl;
    border: 2px solid rgb(0, 181, 152);
    border-radius: 10px; /* Adjust the value to change the roundness */
}

.bs-stepper-circle{
    background:rgb(0, 28,66 )
}

.bs-stepper-label{
    color:rgb(8, 8, 8);
}

.backgroundLog {
  background-image: url('/public/imges/backlogo.png');
  margin: 0;
  padding: 0;
  height: 100vh;
  /* Use viewport height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.backcolorLoging{
  background-color: rgba(255, 255, 255,);
}

.broderLog{
  border: 2px solid #274c5d;
  border-radius: 10px; 
}
.broderSum{
  border: 2px solid rgb(0, 181, 152);
  border-radius: 10px; 
}

.textLog{
  font-size:35px;
  color:rgb(58, 59, 59) ;
}

/* Change image for large screens */
@media screen and (min-width: 1201px) {
  .backgroundLog {
    background-image: url('/public/imges/pageRashi.jpg');
  }
}

.bs-stepper-header .step.active .step-trigger .bs-stepper-circle {
    background-color:rgb(0, 181, 152);; /* Change to your desired color */
  }

  .bg-btn {
    color: white;
    background:rgb(0, 28,66 )

  }

  .btn{
    background:rgb(1, 20, 48);
    border-radius: 8px;
    border: 2px solid #00b598;
  }

  .btnCatgory{
    background:rgb(0, 28,66 );
    border-radius: 8px;
    padding: 8px;
    border: 2px solid rgb(0, 28,66 );
  }

  .btn-rashi{
    background:rgb(245, 245, 245);
    border-radius: 45px;
    color: rgb(0, 0, 0);
    font-family: 'TAN Eeadline', sans-serif;
    border: 4px solid rgb(159, 237, 187);
  }

  .btn-rashiShavah{
    background:#296baa ;
    border-radius: 20px;
    color: rgb(249, 249, 249);
    font-family: 'Anton', sans-serif; /* Add this line */
    border: 2px solid rgb(255, 255, 255);
  }


  .btn-login{
    background:rgb(48, 75, 129);
    border-radius: 8px;
    color: rgb(255, 255, 255);
    font-family: 'Anton', sans-serif; /* Add this line */
    border: 2px solid rgb(48, 75, 129);
  }


  .btn-danger {
    /* background-color: rgb(0, 181, 152); Change to your desired RGB value */
    border-color: rgb(246, 243, 243); /* Change to your desired RGB value */
  }

  /* .btn:hover{
    background-color:rgb(0, 181, 152);
  } */

  .myText {
    font-size: 18px;
    font-family: Georgia, Times, 'Times New Roman', serif  Arial, sans-serif;
  }

  .bs-stepper-label{
      white-space: pre-line;
  }
  

  .select{
    border-color:#315a6c;
  }

  .backAddSah{
    background-color:rgb(129, 135, 143); 
    color: #fff;
    border-radius: 5px;

  }

  input[type="file"] {
    display: none;
  }

  .custom-file-input {
    display: inline-block;
    background-color: #274c5d;
    color: white;
    padding: 10px 40px;
    cursor: pointer;
    border-radius: 5px;
  }


  .button {
    display: inline-block;
    padding: 7px 10px;
    background-color:rgb(146, 151, 151);; /* Button color */
    color: #fff; /* Text color */
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .buttonplus{
    background-color:rgb(3, 3, 3); /* Button color */
    border-radius: 5px;
    margin: 5px;
    color: #fff; /* Text color */

  }

  .buttonRemove {
    display: inline-block;
    padding: 5px 5px;
    background-color:rgb(241, 15, 15);; /* Button color */
    color: #fff; /* Text color */
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #171718; /* Darker button color on hover */
  }

  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Makes it a circle */
    background-color: rgb(50, 214, 50);
    border: 2px solid rgb(0, 0, 0); /* 5px width black border */
}

.circleRed {
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Makes it a circle */
  background-color: rgb(247, 6, 6);
  border: 2px solid rgb(0, 0, 0); /* 5px width black border */
}