

.backColorrshi {
    background-color: #f4f6fa;
}

.backColor {
    background-color: rgb(245, 242, 245);
    border-radius: 45px;
    min-height: 480px;
}

@media screen and (min-width: 1201px) {
    .backColor {
        background-color: rgb(245, 242, 245);
        border-radius: 45px;
        min-height: 580px;
    }
}

.buttonChack {
    background-color: #001c42;
    font-weight: bold;
    border: white;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    border-radius: 45px;
    color: rgb(0, 181, 152);
    ;
}

@media screen and (min-width: 1201px) {
    .buttonChack {
        background-color: #001c42;
        font-weight: bold;
        border: white;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
        border-radius: 45px;
        color: rgb(0, 181, 152);
        ;
    }
}

.titleFont {
    color: #063372;
    direction: rtl;
    font-size: 35px;
    margin-top: 10px;
    font-weight: bold;
    font-family: 'Nunito Sans', sans-serif;
    /* font-family:fantasy */
        /* font-family:'Times New Roman', Times, serif */
        /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

@media screen and (min-width: 1201px) {
    .titleFont {
        color: #063372;
        direction: rtl;
        font-size: 60px;
        margin-top: 10px;
        font-weight: bold;
        font-family: 'Raleway ', sans-serif;
    }
}

@media screen and (min-width: 1201px) {
    .titleFontWeb {
        color: #063372;
        direction: rtl;
        font-size: 35px;
        margin-top: 10px;
        font-weight: bold;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
    }
}


.font {
    color: #2d75da;
    direction: rtl;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.buttonMas {
    background-color: #001c42;
    font-weight: bold;
    border: white;
    height: 30px;
    padding-left: 70px;
    padding-right: 70px;
    font-size: 17px;
    border-radius: 45px;
    color: rgb(0, 181, 152);
    ;
}

@media screen and (min-width: 1201px) {
    .buttonMas {
        background-color: #001c42;
        font-weight: bold;
        border: white;
        height: 50px;
        padding-left: 90px;
        padding-right: 90px;
        font-size: 30px;
        border-radius: 45px;
        color: rgb(0, 181, 152);
        ;
    }
}

.buttonMasShevah {
    background-color: #001c42;
    font-weight: bold;
    border: white;
    height: 30px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 17px;
    border-radius: 45px;
    color: rgb(0, 181, 152);
    ;
}

@media screen and (min-width: 1201px) {
    .buttonMasShevah {
        background-color: #001c42;
        font-weight: bold;
        border: white;
        height: 50px;
        padding-left: 55px;
        padding-right: 55px;
        font-size: 30px;
        border-radius: 45px;
        color: rgb(0, 181, 152);
    }
}

.borderR {
    direction: rtl;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid rgb(179, 176, 176);
}

.borderleft {
    direction: rtl;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border: 1px solid rgb(179, 176, 176);
}

.icon {
    height: 220;
    width: 310;
}