.bodyColor {
  height: 87vh;
  background-color: #f5f2f5;
}

body{
  
  background-color: rgb(245, 242, 245);

}

.ColorBack {
  background-color: #f5f2f5;;
}

.btn-add{
    background:rgb(0, 28,66 );
    border-radius: 45px;
    color: rgb(255, 255, 255);
    font-family: 'TAN Eeadline', sans-serif;
    border: 4px solid rgb(0, 28,66 );
  }


  .addUserBack{
    background-color: rgb(0, 28,66 );
  }

  .btnAddUser {
    font-family: 'TAN Eeadline', sans-serif;
    border-radius: 10px;
    border: 1px solid #9fedbb;
    background-color: rgb(159, 237, 187);
  }


  .broderUser{
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }


  .imageShow{
    background-color: #f5f2f5;
    width: 100vw;
    height: 280px;
  }

  @media screen and (min-width: 1201px) {
    .imageShow {
      background-color: #f5f2f5;
        width: 53vw;
        height: 620px;
    }    
}

.imageMonney{
  width: 100vw;
  height: 220px;
}

@media screen and (min-width: 1201px) {
  .imageMonney {
      width: 55vw;
      height: 620px;
  }
}

.imageLevel{
  width: 100vw;
  height: 750px;
}

@media screen and (min-width: 1201px) {
  .imageLevel {
    width: 80vw;
    height: 449px;
  }
}

.imageWinner{
  width: 100vw;
  height: 300px;
}

@media screen and (min-width: 1201px) {
  .imageWinner {
      width: 45vw;
      height: 600px;
  }
}

.image-container {
  margin: 10px 0; /* Adds some vertical spacing */
}

.image-item {
  height: 500px;
  border-radius: 8px; /* Optional: adds rounded corners */
  overflow: hidden; /* Optional: prevents overflow of content */
}



.wave {
  width: 100%; /* Full width of the container */
  height: 20px; /* Height of the SVG */
}

.wave-path {
  stroke-dasharray: 100; /* Adjust based on the length of the path */
  stroke-dashoffset: 100; /* Start the dash offset to hide the line */
  animation: draw 2s ease-in-out infinite;
}

@keyframes draw {
  0% {
      stroke-dashoffset: 100; /* Hide the line initially */
  }
  50% {
      stroke-dashoffset: 0; /* Show the line */
  }
  100% {
      stroke-dashoffset: 100; /* Hide the line again */
  }
}



.sprite {
  background-image: url("/public/imges/spritesheet.png");
  background-repeat: no-repeat;
  display: block;
}

.sprite-___ {
  width: 1324px;
  height: 342px;
  background-position: -5px -5px;
}

.sprite-github {
  width: 30px;
  height: 30px;
  background-position: -5px -357px;
}

.sprite-gmail {
  width: 30px;
  height: 30px;
  background-position: -45px -357px;
}

.sprite-linkedin {
  width: 30px;
  height: 30px;
  background-position: -85px -357px;
}

.sprite-stackoverflow {
  width: 30px;
  height: 30px;
  background-position: -125px -357px;
}

.sprite-tumblr {
  width: 30px;
  height: 30px;
  background-position: -165px -357px;
}

.sprite-twitter {
  width: 30px;
  height: 30px;
  background-position: -205px -357px;
}


.scroll-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.scroll-section {
  height: 100vh;
  scroll-snap-align: start;
  transition: opacity 0.5s;
}

.textPage {
  direction: rtl;
  font-size: 20px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
