.col.text-center {
    text-align: center !important;
}

.myTitle {
    font-size: 30px;
    color: rgb(6, 45, 124);
    font-family: Georgia, Times, 'Times New Roman', serif Arial, sans-serif;
}

.myTitleSub {
    font-size: 20px;
    color: rgb(0, 181, 152);
    font-family: Georgia, Times, 'Times New Roman', serif Arial, sans-serif;
}

.myTitleMess {
    font-size: 25px;
    color: rgb(6, 45, 124);
    font-family: Georgia, Times, 'Times New Roman', serif Arial, sans-serif;
}

.btnn {
    border-radius: 50px;
}

.message-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    background-color: #f9f9f9;
}

.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #aaa;
}

.close-button:hover {
    color: #666;
}

.message-content {
    margin-top: 5px;
}


.backgroundImgRashi {
    background-image: url('/public/imges/logoEnterPhone.png');
    margin: 0;
    padding: 0;
    width: 325px;
    height: 265px;
    /* Use viewport height */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (min-width: 1101px) {
    .backgroundImgRashi {
        background-image: url('/public/imges/logoEnter.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 750px;
        height: 325px;

    }
}


.messBox {
    height: 60px;
    width: 285px;
}


@media screen and (min-width: 1201px) {
    .messBox {
        height: 60px;
        width: 365px;

    }
}

.hrRashi {
    margin-left: 20px;
    margin-right: 20px;
    border-top: 3px solid;
    color: #0df6cf;

}


.broderWork{
    border: 2px solid rgb(129, 134, 134);
    border-radius: 10px; 
  }

  .titlePageHowUs {
    border: 3px solid rgb(0, 181, 152);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px; 
    color:  rgb(213, 216, 216);
    background-color: rgb(0, 28,66 );
  }

  .backUs{
    border: 2px solid rgb(0, 181, 152);
    background-color: rgb(245, 245, 245);
  }