.select {
  border: 2px solid #274c5d;
  /* 2px wide solid black border */
  border-radius: 10px;
  /* Rounded corners */
  border-color: #274c5d;
}

.background1 {
  /* background-image: url('/public/imges/pitronot.png'); */
  margin: 0;
  padding: 200;
  height: 100vh;
  /* Use viewport height */
  background-size: cover;
  background-position: center;

}


.diagonal-blue {
  height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom right, #001572 55%, transparent 85%);
  /* clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%); */
}

.grad1 {
  height: 100vh;
  background-image: linear-gradient(to right top, #242763, #273068, #2c396c, #324270, #394b73, #4c5a7e, #5f6a89, #727a94, #9296aa, #b2b3c1, #d2d1d9, #f1f1f1);
}

.logoimage {
  background: url('/public/imges/line.png');

}



.gradient1 {
  height: 100vh;
  background-image: linear-gradient(35deg,
      hsl(223deg 68% 6%) 0%,
      hsl(222deg 67% 12%) 27%,
      hsl(223deg 62% 18%) 41%,
      hsl(223deg 61% 23%) 50%,
      hsl(223deg 58% 29%) 57%,
      hsl(223deg 56% 35%) 63%,
      hsl(223deg 54% 41%) 68%,
      hsl(223deg 54% 51%) 73%,
      hsl(223deg 54% 61%) 78%,
      hsl(223deg 54% 70%) 83%,
      hsl(223deg 54% 80%) 88%,
      hsl(222deg 53% 90%) 94%,
      hsl(0deg 0% 100%) 100%);
}

.gradient {
  height: 100vh;
  background-image: linear-gradient(50deg,
      hsl(223deg 63% 18%) 0%,
      hsl(223deg 66% 22%) 24%,
      hsl(223deg 69% 27%) 39%,
      hsl(223deg 73% 31%) 49%,
      hsl(223deg 77% 35%) 58%,
      hsl(223deg 81% 38%) 65%,
      hsl(223deg 55% 48%) 72%,
      hsl(223deg 53% 59%) 79%,
      hsl(223deg 54% 71%) 85%,
      hsl(223deg 53% 84%) 92%,
      hsl(0deg 0% 98%) 100%);
  /* background-image: linear-gradient(
      50deg,
      hsl(223deg 81% 29%) 6%,
      hsl(223deg 73% 36%) 49%,
      hsl(223deg 65% 43%) 62%,
      hsl(223deg 57% 50%) 70%,
      hsl(223deg 49% 57%) 75%,
      hsl(223deg 40% 64%) 79%,
      hsl(223deg 33% 71%) 83%,
      hsl(223deg 25% 78%) 87%,
      hsl(222deg 16% 85%) 90%,
      hsl(220deg 7% 92%) 95%,
      hsl(0deg 0% 98%) 100%
    ); */
  /* background-image: linear-gradient(
      50deg,
      hsl(223deg 81% 29%) 4%,
      hsl(231deg 47% 39%) 44%,
      hsl(234deg 33% 46%) 57%,
      hsl(235deg 27% 52%) 65%,
      hsl(235deg 25% 58%) 71%,
      hsl(233deg 23% 63%) 76%,
      hsl(228deg 21% 69%) 80%,
      hsl(216deg 18% 75%) 84%,
      hsl(186deg 13% 80%) 88%,
      hsl(133deg 25% 88%) 93%,
      hsl(104deg 100% 95%) 100%
    ); */
}


.backcolorShow{
  background-color: rgb(245, 242, 245);
}


.btnCall {
  font-family: 'TAN Eeadline', sans-serif;
  border-radius: 50px;
  border: 1px solid #9fedbb;
  background-color: rgb(159, 237, 187);
}

.btnNext {
  font-family: 'TAN Eeadline', sans-serif;
  border-radius: 10px;
  color: white;
  border: 1px solid #0a3f9d;
  background-color: rgb(10, 63, 157);
}

.btnAdd {
  font-family: 'TAN Eeadline', sans-serif;
  border-radius: 10px;
  border: 1px solid #274c5d;
  background-color: #274c5d;
}

.btnAsk {
  font-family: 'TAN Eeadline', sans-serif;
  border-radius: 10px;
  border: 1px solid #274c5d;
  background-color: #274c5d;
}

.btnFinish {
  font-family: 'TAN Eeadline', sans-serif;
  border-radius: 10px;
  border: 1px solid #274c5d;
  background-color: #274c5d;
}

.brod {
  border-radius: 30px;
}

.backBlue{
  height: 500px;
  width: 100vw;
  border-radius: 30px;

}


.backimageBox{
  background-image: url("/public/imges/backgoundblue.png");
  margin: 0;
  border-radius: 50px;
  padding: 200;
  height: 440px;
  /* Use viewport height */
  background-size: cover;
  background-position: center;
}


.backimageBoxLogin{
  background-image: url("/public/imges/backgoundblue.png");
  margin: 0;
  border-radius: 50px;
  padding: 200;
  height: 370px;
  /* Use viewport height */
  background-size: cover;
  background-position: center;
}


.bodyl {
  height: 100vh;
  background-color: #f5f2f5;
}




.textt {
  direction: rtl;
  font-size: 20px;
  font-family: 'TAN Eeadline', sans-serif;
  color: #dbd9d9;
}



@media screen and (min-width: 1201px) {
  .textt {
    direction: rtl;
    font-size: 30px;
    font-family: 'TAN Eeadline', sans-serif;
    color: #dbd9d9;
  }
}

.sizewithe {
  width: 300px;
}

@media screen and (min-width: 1201px) {
  .sizewithe {
    width: 700px;
  }
}

.text-row {
  display: flex;
  justify-content: center;
  /* Center the text horizontally */
  align-items: center;
  /* Center the text vertically if needed */
  flex-wrap: wrap;
  /* Allow text to wrap if necessary */
  width: 75%;
  /* Use the same width as your original class */
  margin: 0 auto;
  /* Center the div */
}

.text-rowf {
  display: flex;
  justify-content: center;
  /* Center the text horizontally */
  align-items: center;
  /* Center the text vertically if needed */
  flex-wrap: wrap;
  /* Allow text to wrap if necessary */
  margin: 0 auto;
  /* Center the div */
}

.text-white {
  color: white;
  /* Ensure text color is white */
  margin: 0;
  /* Remove default paragraph margins */
}

.tan-headline {
  color: #D2B48C;
  /* Tan color */
  font-family: 'Lora', serif;
  /* Use the chosen font */
  font-size: 2.5rem;
  /* Adjust the size */
  text-align: center;
  /* Center the text */
  margin: 20px 0;
  /* Add some margin */
}


.backgroundtach {
  background-image: url('/public/imges/tachbackphone.png');
  margin: 0;
  padding: 200;
  height: 100vh;
  /* Use viewport height */
  background-size: cover;
  background-position: center;
}

@media screen and (min-width: 1201px) {
  .backgroundtach {
    background-image: url('/public/imges/tachback.png');
  }
}

.backgroundTex {
  background-image: url('/public/imges/pitronot.png');
  /* Use viewport height */
  background-size: auto;
  background-repeat: no-repeat;
}

.backColor {
  min-height: 100vh;
  background-color: rgb(0, 28, 66);
}

/* Change image for large screens */
@media screen and (min-width: 1201px) {
  .background1 {
    background-image: url('/public/imges/pitronotpici.png');
  }
}

@media screen and (min-width: 700px) {
  .background1 {
    background-image: url('/public/imges/pitronotpici.png');
  }
}


@media screen and (min-width: 1201px) {
  .btnBig {
    font-size: xx-large;
  }
}

.animated-button {
  opacity: 0;
  position: relative;
  animation: appear 3s ease forwards, move 3s ease forwards;
  /* Define animations */
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes move {
  from {
    transform: translate(-50px);
    /* Move up by 50px */
  }

  to {
    transform: translateY(0);
    /* Move back to original position */
  }
}
.left-align {
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.an {
  animation: appear 2s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.anBack {
  animation: appearBack 1s ease forwards
}

@keyframes appearBack {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.dialog-container {
  position: relative;
}

.animated-b {
  opacity: 0;
  position: relative;
  animation: appear 2s ease forwards, moveUpDown 3s ease forwards;
  /* Define animations */
}

.state-back {
  z-index: 1;
  /* Ensure backdrop is behind the button */
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes moveUpDown {
  from {
    transform: translateY(-15%);
  }

  to {
    transform: translateY(0);
  }
}


.custom-image {
  position: absolute
    /* Add more styles as needed */
}

.back {
  border-radius: 2%;
  background-color: rgba(239, 236, 236, 0.797);
}

@media screen and (min-width: 1201px) {
  .back {
    height: fit-content;
    width: 60%;
    background-color: rgba(239, 236, 236, 0.797);
    border-radius: 1%;
  }
}

.background2 {
  background-color: rgb(237, 236, 236);
  min-height: 100vh;
  /* Replace with your desired color */
  /* Other background styles */
}

.centered-button1 {
  position: relative;
  top: 30%;
}

.centered-button {
  position: relative;
  top: 30%;
}

.titlecheck{
  color: rgb(48, 75, 129);
}
.titlecheckK{
  color: rgb(48, 75, 129);
  font-weight: bold;
}
.buttonDialog {
  border: 3px solid rgb(48, 75, 129);;
  /* 5px width black border */
  display: inline-block;
  padding: 8px;
  background-color: rgb(48, 75, 129);
  ;
  /* Button color */
  color: #fff;
  /* Text color */
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
  font-weight: 500;
}


.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}


.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  /* Define the size of the checkbox */
  width: 28px;
  height: 28px;
}


.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}


#NagishLiTrigger {
  right: 0 !important;
  left: 5px;
  margin-bottom: 43px;
  filter: hue-rotate(700deg);
}

span.nagishli-trigger-icon {
  width: 35px;
  height: 36px;
  box-shadow: 0 0 0 2px #ffffff;
  border-radius: 30%;
  padding: 0px;
  padding-top: 0px;
}